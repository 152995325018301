<template>
    <form-modal
        ref="formModal"
        :title="isPosChannelCreated ? i18nEditPosChannel : i18nNewPosChannel"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nName"
                            label-for="name-input"
                            class="required label-pdv"
                            :invalid-feedback="i18nChannelInvalidNameLabel"
                        >
                            <b-form-input
                                id="name-input"
                                v-model.trim="$v.posChannelModal.name.$model"
                                class="input-pdv-blue"
                                name="name"
                                type="text"
                                :disabled="submitLoading"
                                :state="validateField('name', 'posChannelModal')"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('name', 'posChannelModal')"
                                @blur="$v.posChannelModal.name.$touch"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                v-if="isPosChannelCreated"
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <add-button
                v-else
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <cancel-label-button
                ref="cancelButton"
                :busy="submitLoading"
                @onClick="handleCancel"
            />
        </template>
    </form-modal>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators'

import PosChannelsService from '@src/services/PosChannelsService'

import FormModal from '@/src/components/FormModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import CancelLabelButton from '@/src/components/CancelLabelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import cloneObject from '@utils/clone-object'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        CancelLabelButton,
        AddButton,
        SaveButton,
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    data() {
        return {
            i18nKey,
            i18nCommon,
            posChannelModal: {},
            submitLoading: false,
        }
    },
    validations: {
        posChannelModal: {
            name: {
                required,
                minLength: minLength(2),
            },
        },
    },
    computed: {
        isPosChannelCreated() {
            return this.posChannelModal.id > 0
        },
        modalOperation() {
            return this.isPosChannelCreated ? 'edited' : 'created'
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nChannelInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nNewPosChannel() {
            return this.getI18n(i18nKey, 'BUTTONS.new_channel')
        },
        i18nEditPosChannel() {
            return `${this.getI18n(i18nCommon, 'edit')} ${this.getI18n(
                i18nKey,
                'TITLES.channel'
            )}`
        },
        i18nPosChannel() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.channel',
                modifier: 1,
            })
        },
    },
    methods: {
        showModal(data) {
            this.submitLoading = false

            if (data) {
                this.posChannelModal = cloneObject(data);
            } else {
                this.posChannelModal = {
                    id: 0,
                    name: '',
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.posChannelModal.$touch()
            if (!this.$v.posChannelModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },
        async handleSubmit() {
            const operation = this.isPosChannelCreated
                ? PosChannelsService.update(
                      this.posChannelModal.id,
                      this.posChannelModal
                  )
                : PosChannelsService.create(this.posChannelModal)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(
                    this.i18nPosChannel,
                    this.modalOperation,
                    0
                )
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
    },
}
</script>
