import BaseService from '@src/services/BaseService'

class PosChannelsService extends BaseService {
    constructor() {
        super('pos-channels')
    }

    removeAllPos(id, params) {
        this.axios.post(`${this.getUrl()}/${id}/remove-all-pos`, params)
    }
}

export default new PosChannelsService()
